<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <a-input v-model="params.title" size='small' style="width:150px;" placeholder='标题'></a-input>
                <a-input v-model="params.author" size='small' style="width:150px;margin-left:10px;" placeholder='作者'></a-input>
                <a-select  allowClear v-model="params.isRecommend" size='small' style="width:100px;margin:5px;" placeholder='推荐状态'>
                   <a-select-option :value='0'>否</a-select-option>
                    <a-select-option :value='1'>是</a-select-option>
                </a-select>
                <a-input v-model="params.dynastyName" size='small' style="width:150px;margin-left:10px;" placeholder='朝代'></a-input>
                <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button>
            </div>
            <a-button size='small' @click="openModal({})">新增文章/问答</a-button>
        </div>
        <div class="table-content" >
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">
                <!-- <div slot="Image" slot-scope="row" style="width:100px;height:100px;display:flex;justify-content:center;align-items:center;">
                    <img style="max-width:100px;max-height:100px;" :src="row">
                </div> -->
                <div slot="Image" slot-scope="row">
                    <div style='max-width:100px;max-height:100px;display:flex;justify-content:center;align-items:center;'>
                        <img @click="previewImg(row)" style='max-width:100px;max-height:100px;' :src='row.coverUrl'/>
                    </div>
                </div>
                <div slot="Mannage" slot-scope="row">
                    <a class="map-margin" @click="openModal(row)">编辑</a>

                    <a v-if="row.isRecommend == '0'" class="map-margin" @click="editRecommend(row) ">推荐</a>
                    <a v-else class="map-margin" @click="editRecommend(row) ">取消推荐</a>
                   
                    <a-popconfirm
                        title="Are you sure delete this item?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="remove(row)"
                    >
                    <a style='margin-left:5px'>删除</a>
                    </a-popconfirm>
                </div>
            </a-table>
        </div>

        <a-modal centered :width='1000' :title='computedInnerTitle' v-model="showModal" @ok='checkForms' @cancel='cancel'>
            <div style="height:650px;overflow-y:auto;">
                <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
                    <a-form-model-item label='大币种' prop='coinId'>
                        <a-select @change="changeSelect" @search='searchData' ref='addse' v-model="forms.coinId" :filter-option="untils.filterOption" showSearch placeholder='请选择'>
                            <a-select-option v-for="item of currency_big_list" :key="item.id" :value='item.id'>{{item.coinKindName}}<span v-if="item.dynastyName"> {{item.dynastyName}}</span><span v-if="item.dynastyName"> {{item.rulerName}}</span></a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label='类型' prop='type'>
                        <a-select @change="selectType" placeholder='选择类型' v-model="forms.type">
                            <a-select-option value='article'>文章</a-select-option>
                            <a-select-option value='answer'>问答</a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="类别" prop="categoryId" >
                        <a-select size='small' @search='searchData' ref='addse' v-model="forms.categoryId" :filter-option="untils.filterOption" showSearch placeholder='请选择' >
                            <a-select-option v-for="item of this.category_list" :key="item.id" :value='item.id'>{{item.title}}</a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label='可见类型'>
                        <a-select @change="visibleTypeChange" placeholder='可见类型' v-model="forms.visibleType">
                            <a-select-option value='global'>全局</a-select-option>
                            <a-select-option value='me'>仅自己</a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="图片" >
                        <a-upload
                        :key="imgKey"
                        :action="ip+'/file/upload'"
                        list-type="picture-card"
                        :file-list="fileList"
                        @preview="handlePreview"
                        @change="handleChange"
                    >
                    <div >
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传图片</div>
                    </div>
                    </a-upload>
                    </a-form-model-item>
                
                    <a-form-model-item label='创建时间'>
                        <a-date-picker show-time size='small' :defaultValue="moment(new Date, 'YYYY-MM-DD')" placeholder='选择日期' @change="changeTime" />
                    </a-form-model-item>
                    <a-form-model-item label='标题' prop='title'>
                        <a-input placeholder='请输入标题' v-model="forms.title"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label='作者' prop='userId'>
                        <a-select size='small' ref='addse' v-model="forms.userId" :filter-option="untils.filterOption" showSearch placeholder='请选择' >
                            <a-select-option v-for="item of this.user_list" :key="item.id" :value='item.id'>{{item.userName}}</a-select-option>
                        </a-select>
                        <!-- <a-input placeholder='请输入作者' v-model="forms.author"></a-input> -->
                    </a-form-model-item>
                    <a-form-model-item label='内容'>
                        <a-textarea auto-size size='small' placeholder='请输入内容' v-model="forms.content"> 1</a-textarea>
                        
                    </a-form-model-item>
                    <a-form-model-item label='回答列表' v-if="forms.type == 'answer'&&forms.id">
                        <div class="answer-list">
                            <div class="answer-item" v-for="(item,index) of answer_list" :key="index">
                                <div style="flex:1;display:flex;">
                                    <span style="color:green;">{{item.userName}}：</span>
                                    <div v-html="item.answerContent"></div>
                                    <!-- <span>{{item.answerContent}}</span> -->
                                </div>

                                <div>
                                    
                                    <a-button v-if="item.isRecommend == '1'" @click="answerRecommend(item.id)" size='small' style="margin-right:10px;" type='primary'>取消推荐</a-button>
                                    <a-button v-else  @click="answerRecommend(item.id)" size='small' style="margin-right:10px;" type='primary'>推荐</a-button>
                                    <a-button @click="openAnswerpop(item)" size='small' style="margin-right:10px;" type='primary'>编辑</a-button>
                                    <a-button @click="clearAnswer(item.id)" size='small' type='danger'>删除</a-button>
                                </div>
                            </div>
                            <a-button @click="visible = true" v-if="forms.type == 'answer'&&forms.id" type="primary">新增回答</a-button>
                        </div>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </a-modal>
        <a-modal :width='800' v-model="visible" title='新增回复' @ok="postAnswer(forms.id)">
            <a-form-model v-bind="layoutAsnswe">
                <a-form-model-item label='选择用户'>
                    <a-select v-model="answerForms.userId" placeholder='选择用户' :filter-option="untils.filterOption" showSearch style="width:100%;margin-bottom:20px;">
                        <a-select-option v-for="item of user_list" :key="item.id" :value='item.id'>
                            <div style="display:flex;align-items:center;">
                                <img style="max-width:20px;max-height:20px;border-radius:50%;margin-right:10px;" :src="item.userAvatar" alt="">
                                <span :text='item.userName'>{{item.userName}}</span>
                            </div>
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label='回复内容'>
                    <quillEditor :options='options' v-model="answerForms.answerContent" style="min-height:200px;"></quillEditor>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {quillEditor} from 'vue-quill-editor'
import quillConfig from '../../../untils/qull.config'
import {mapState} from 'vuex'
import moment from 'moment'
export default {
    data(){
        return {
            columns:[
                {title:'图片',scopedSlots: { customRender: 'Image' },},
                {title:'大币种',dataIndex:'coinName'},
                {title:'类型',dataIndex:'type',
                    customRender:(text)=>{
                        const inner = text == 'article' ? '文章' : '问答'
                        return <span>{inner}</span>
                    }
                },
                {title:'分类',dataIndex:'categoryName'},
                {title:'标题',align:'center',dataIndex:'title'},
                {title:'作者',dataIndex:'author'},
                {title:'推荐',dataIndex:'isRecommend',
                    customRender:(text)=>{
                        const inner = text == '0' ? '否' : '是'
                        if(inner == '是'){
                            return <span style='color:green'>{inner}</span>
                        }else{
                            return <span>{inner}</span>
                        }
                    }
                },
                {title:'可见',dataIndex:'visibleStatus',
                    customRender:(text)=>{
                        const inner = text
                        if(inner == '0'){
                            return <span style='color:green'>全局</span>
                        }else{
                            return <span style='color:red'>仅自己</span>
                        }
                    }
                },
                {title:'发布时间',align:'center',dataIndex:'createTime'},
                {title:'朝代',align:'center',dataIndex:'dynastyName'},
                {title:'管理',align:'center',scopedSlots: { customRender: 'Mannage' },},
            ],
            data:[],
            params:{pageNum:1,pageSize:10,userName:'',userPhone:'',},
            pagination:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            IO:0,// 0 写入 1新增,
            forms:{
                coverUrl:'',
                type:'answer',
                visibleType:'global',
            },
            fileList:[],
            rules:{
                coinId:[{ required: true, message: '大币种', trigger: 'change' }],
                userId:[{ required: true, message: '作者', trigger: 'blur' }],
                title:[{ required: true, message: '标题', trigger: 'blur' }],
                type:[{type:'string', required: true, message: '类型', trigger: 'change' }],
                visibleType:[{type:'string', required: true, message: '可见类型', trigger: 'change' }],
            },
            showModal:false,
            layout:{
                labelCol: { span:2},//标签占2份  共24份
                wrapperCol: { span:22},
            },
            layoutAsnswe:{
                labelCol: { span:2},//标签占2份  共24份
                wrapperCol: { span:22},
            },
            currency_big_list:[],//大币种
            options:quillConfig,
            visible:false,
            user_list:[],
            category_list:[],
            answerForms:{
                answerContent:'',
                userId:null
            },
            answer_list:[],
            imgKey:'',
        }
    },
    components:{
        quillEditor,
    },
    computed:{
        ...mapState(['ip']),
        computedInnerTitle(){
            const {type = 'article'} = this.forms
            let inner = ``
            if(this.IO == 1){
                inner = `编辑${type == 'article' ? '文章' : '问答'}` 
            }else{
                inner = `新增${type == 'article' ? '文章' : '问答'}` 
            }
            return inner
        },
    },
    watch:{
        visible(m){
            if(!m){this.answerForms = {}}
        }
    },
    created(){
        this.getData(this.params)
        this.getUsers()
    },
    methods:{
        moment,
        async getData(params){
            this.loading = true
            const res = await this.axios('/dq_admin/wdQuestion/list',{params:params})
            this.loading = false
            const {records,total} = res.data
            this.data = records
            this.pagination.total = total
        },
        search(){
            this.params.pageNum = 1
            this.getData(this.params)
        },
        
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.params.pageNum = current
            this.params.pageSize = pageSize
            this.getData(this.params)
        },
        openModal(row){
            this.showModal = true
            const {id = null} = row
            this.IO = id ? 1 : 0
            this.fileList = []
           
            if(this.IO == 1){//updata
                this.forms = JSON.parse(JSON.stringify(row))
                const {type,coinName} = this.forms
                this.searchData(coinName)
                this.getUsers()
                this.getCategoryList({pageNum:1,pageSize:100})
                
                this.forms.categoryId = row.categoryId
                this.forms.userId = row.userId
                if(row.visibleStatus == 0){
                    this.forms.visibleType = 'global';
                }else{
                    this.forms.visibleType = 'me';
                }
                if(type == 'answer'){
                    this.getAnswerList(this.forms.id)
                }
                
                const {coverUrls} = row || {coverUrls:[]}
                if(coverUrls&&coverUrls.length>0){
                    this.fileList = coverUrls.map((items,i)=>{
                        const obj = {
                            uid:i,name: 'image',status: 'done',url:items,
                        }
                        return obj
                    })
                }
            }else{//add
                Object.assign(this.forms,{type:'answer'})
            }
            
        },
        getCategoryList(params) {
            this.axios("/dq_admin/wdCategory/list", {
            params: params,
        }).then((res) => {
            let list = res.data.records;
            this.category_list = list;
            });
        },

        editRecommend(row) {
            const editParam = {questionId:row.id,isRecommend:row.isRecommend}
            this.axios("/dq_admin/wdQuestion/editRecommend", {
            params: editParam,
        }).then((res) => {
            this.getData(this.params)
            });
        },


        searchData(inner){
            this.axios('/dq_admin/kind/list',{params:{
                coinName: inner
            }}).then(res=>{
                if(res.status == '200'){
                    const {records} = res.data
                    this.currency_big_list = records
                }
            })
        },
        
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleChange({fileList}){
            fileList.map((row)=>{
                try{
                    if(row.response.data){
                        row.url = row.response.data
                    }
                }catch(e){

                }
            })
            this.fileList = fileList
        },

        async postAnswer(questionId){
            if(questionId){
                Object.assign(this.answerForms,{questionId})
            }
            const res = await this.axios.post('/dq_admin/wdQuestionAnswer/addOrEdit',this.answerForms)
            if(res.status == 200){
                const {message} = res
                this.$message.success(message)
                this.visible = false
                this.getAnswerList()
            }
        },
        async submit(){
            // const postData = JSON.parse(JSON.stringify(forms))
            const images = this.fileList.map(row=>row.url)
            this.forms.coverUrls = images
            const res = await this.axios.post('/dq_admin/wdQuestion/addOrEdit',this.forms)
            if(res.status == 200){
                const {message} = res
                this.$message.success(message)
                this.getData(this.params)
                this.showModal = false
                this.cancel()
            }
        },
        cancel(){
            this.forms = {coverUrl:''}
            this.$refs.ruleForm.resetFields();
        },
        checkForms(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false;
                }
            });
        },
        parsetCallback(type,result){
            this.forms.coverUrl = result
        },
        async remove(row){
            const {id} = row
            const res = await this.axios.post('/dq_admin/wdQuestion/del',{id})
            if(res.status == 200){
                const {message} = res
                this.$message.success(message)
                this.getData(this.params)
            }
        },
        async getUsers(){
            const res = await this.axios('/dq_admin/user/list')
            if(res.status == 200){
                const {data} = res
                this.user_list = data
            }
        },
        async getAnswerList(){
            const res = await this.axios('/dq_admin/wdQuestionAnswer/listByQuestionId',{params:{questionId:this.forms.id}})
            if(res.status == 200){
                const {data} = res
                this.answer_list = data
            }
        },
        openAnswerpop(item){
            this.visible = true
            Object.assign(this.answerForms,item)
        },
        async clearAnswer(id){
            const res = await this.axios.post('/dq_admin/wdQuestionAnswer/del',{id})
            if(res.status == 200){
                const {message} = res
                this.$message.success(message)
                this.getAnswerList()
            }
        },

        async answerRecommend(id){
            const res = await this.axios.post('/dq_admin/wdQuestionAnswer/recommend',{id})
            if(res.status == 200){
                const {message} = res
                this.$message.success(message)
                this.getAnswerList()
            }
        },

        changeSelect(e){
            this.forms.coinId = e
        },
        changeTime(time){
            const {_d} = time
            this.forms.createTime = new Date(_d).toLocaleDateString().replace(/\//g,"-")
        },
        selectType(e){
            Object.assign(this.forms,{type:e})
        },
        previewImg(row){
            if(row.coverUrls&&row.coverUrls.length>0){
                this.$previewImg({
                    list:row.coverUrls.reduce((pre,cur)=>{
                        const obj = {img_url:cur}
                        pre = pre.concat(obj)
                        return pre
                    },[]),
                    
                    baseImgField:'img_url',
                    showMute:false,
                })
            }
        },
      
        visibleTypeChange(evalue) {
            this.$forceUpdate();
            this.forms.visibleType = evalue;
        },
    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
.take-box{width:100%;max-height:700px;}
.answer-list{padding: 0 10px;
    .answer-item{display: flex;justify-content: space-between;align-items: flex-end;}
}
</style>