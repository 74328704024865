var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "search-attr" },
        [
          _c(
            "div",
            [
              _c("a-input", {
                staticStyle: { width: "150px" },
                attrs: { size: "small", placeholder: "标题" },
                model: {
                  value: _vm.params.title,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "title", $$v)
                  },
                  expression: "params.title"
                }
              }),
              _c("a-input", {
                staticStyle: { width: "150px", "margin-left": "10px" },
                attrs: { size: "small", placeholder: "作者" },
                model: {
                  value: _vm.params.author,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "author", $$v)
                  },
                  expression: "params.author"
                }
              }),
              _c(
                "a-select",
                {
                  staticStyle: { width: "100px", margin: "5px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "推荐状态"
                  },
                  model: {
                    value: _vm.params.isRecommend,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "isRecommend", $$v)
                    },
                    expression: "params.isRecommend"
                  }
                },
                [
                  _c("a-select-option", { attrs: { value: 0 } }, [
                    _vm._v("否")
                  ]),
                  _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("是")])
                ],
                1
              ),
              _c("a-input", {
                staticStyle: { width: "150px", "margin-left": "10px" },
                attrs: { size: "small", placeholder: "朝代" },
                model: {
                  value: _vm.params.dynastyName,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "dynastyName", $$v)
                  },
                  expression: "params.dynastyName"
                }
              }),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small" },
                  on: { click: _vm.search }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _c(
            "a-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.openModal({})
                }
              }
            },
            [_vm._v("新增文章/问答")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "Image",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "max-width": "100px",
                          "max-height": "100px",
                          display: "flex",
                          "justify-content": "center",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            "max-width": "100px",
                            "max-height": "100px"
                          },
                          attrs: { src: row.coverUrl },
                          on: {
                            click: function($event) {
                              return _vm.previewImg(row)
                            }
                          }
                        })
                      ]
                    )
                  ])
                }
              },
              {
                key: "Mannage",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a",
                        {
                          staticClass: "map-margin",
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      row.isRecommend == "0"
                        ? _c(
                            "a",
                            {
                              staticClass: "map-margin",
                              on: {
                                click: function($event) {
                                  return _vm.editRecommend(row)
                                }
                              }
                            },
                            [_vm._v("推荐")]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "map-margin",
                              on: {
                                click: function($event) {
                                  return _vm.editRecommend(row)
                                }
                              }
                            },
                            [_vm._v("取消推荐")]
                          ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "Are you sure delete this item?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row)
                            }
                          }
                        },
                        [
                          _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("删除")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { centered: "", width: 1000, title: _vm.computedInnerTitle },
          on: { ok: _vm.checkForms, cancel: _vm.cancel },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { height: "650px", "overflow-y": "auto" } },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "ruleForm",
                    attrs: { model: _vm.forms, rules: _vm.rules }
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "大币种", prop: "coinId" } },
                    [
                      _c(
                        "a-select",
                        {
                          ref: "addse",
                          attrs: {
                            "filter-option": _vm.untils.filterOption,
                            showSearch: "",
                            placeholder: "请选择"
                          },
                          on: {
                            change: _vm.changeSelect,
                            search: _vm.searchData
                          },
                          model: {
                            value: _vm.forms.coinId,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "coinId", $$v)
                            },
                            expression: "forms.coinId"
                          }
                        },
                        _vm._l(_vm.currency_big_list, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [
                              _vm._v(_vm._s(item.coinKindName)),
                              item.dynastyName
                                ? _c("span", [
                                    _vm._v(" " + _vm._s(item.dynastyName))
                                  ])
                                : _vm._e(),
                              item.dynastyName
                                ? _c("span", [
                                    _vm._v(" " + _vm._s(item.rulerName))
                                  ])
                                : _vm._e()
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "类型", prop: "type" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "选择类型" },
                          on: { change: _vm.selectType },
                          model: {
                            value: _vm.forms.type,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "type", $$v)
                            },
                            expression: "forms.type"
                          }
                        },
                        [
                          _c(
                            "a-select-option",
                            { attrs: { value: "article" } },
                            [_vm._v("文章")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "answer" } },
                            [_vm._v("问答")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "类别", prop: "categoryId" } },
                    [
                      _c(
                        "a-select",
                        {
                          ref: "addse",
                          attrs: {
                            size: "small",
                            "filter-option": _vm.untils.filterOption,
                            showSearch: "",
                            placeholder: "请选择"
                          },
                          on: { search: _vm.searchData },
                          model: {
                            value: _vm.forms.categoryId,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "categoryId", $$v)
                            },
                            expression: "forms.categoryId"
                          }
                        },
                        _vm._l(this.category_list, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(_vm._s(item.title))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "可见类型" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "可见类型" },
                          on: { change: _vm.visibleTypeChange },
                          model: {
                            value: _vm.forms.visibleType,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "visibleType", $$v)
                            },
                            expression: "forms.visibleType"
                          }
                        },
                        [
                          _c(
                            "a-select-option",
                            { attrs: { value: "global" } },
                            [_vm._v("全局")]
                          ),
                          _c("a-select-option", { attrs: { value: "me" } }, [
                            _vm._v("仅自己")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "图片" } },
                    [
                      _c(
                        "a-upload",
                        {
                          key: _vm.imgKey,
                          attrs: {
                            action: _vm.ip + "/file/upload",
                            "list-type": "picture-card",
                            "file-list": _vm.fileList
                          },
                          on: {
                            preview: _vm.handlePreview,
                            change: _vm.handleChange
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("上传图片")
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "创建时间" } },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          "show-time": "",
                          size: "small",
                          defaultValue: _vm.moment(new Date(), "YYYY-MM-DD"),
                          placeholder: "选择日期"
                        },
                        on: { change: _vm.changeTime }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "标题", prop: "title" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入标题" },
                        model: {
                          value: _vm.forms.title,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "title", $$v)
                          },
                          expression: "forms.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "作者", prop: "userId" } },
                    [
                      _c(
                        "a-select",
                        {
                          ref: "addse",
                          attrs: {
                            size: "small",
                            "filter-option": _vm.untils.filterOption,
                            showSearch: "",
                            placeholder: "请选择"
                          },
                          model: {
                            value: _vm.forms.userId,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "userId", $$v)
                            },
                            expression: "forms.userId"
                          }
                        },
                        _vm._l(this.user_list, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(_vm._s(item.userName))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "内容" } },
                    [
                      _c(
                        "a-textarea",
                        {
                          attrs: {
                            "auto-size": "",
                            size: "small",
                            placeholder: "请输入内容"
                          },
                          model: {
                            value: _vm.forms.content,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "content", $$v)
                            },
                            expression: "forms.content"
                          }
                        },
                        [_vm._v(" 1")]
                      )
                    ],
                    1
                  ),
                  _vm.forms.type == "answer" && _vm.forms.id
                    ? _c(
                        "a-form-model-item",
                        { attrs: { label: "回答列表" } },
                        [
                          _c(
                            "div",
                            { staticClass: "answer-list" },
                            [
                              _vm._l(_vm.answer_list, function(item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "answer-item" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          flex: "1",
                                          display: "flex"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "green" } },
                                          [_vm._v(_vm._s(item.userName) + "：")]
                                        ),
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              item.answerContent
                                            )
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      [
                                        item.isRecommend == "1"
                                          ? _c(
                                              "a-button",
                                              {
                                                staticStyle: {
                                                  "margin-right": "10px"
                                                },
                                                attrs: {
                                                  size: "small",
                                                  type: "primary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.answerRecommend(
                                                      item.id
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("取消推荐")]
                                            )
                                          : _c(
                                              "a-button",
                                              {
                                                staticStyle: {
                                                  "margin-right": "10px"
                                                },
                                                attrs: {
                                                  size: "small",
                                                  type: "primary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.answerRecommend(
                                                      item.id
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("推荐")]
                                            ),
                                        _c(
                                          "a-button",
                                          {
                                            staticStyle: {
                                              "margin-right": "10px"
                                            },
                                            attrs: {
                                              size: "small",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openAnswerpop(item)
                                              }
                                            }
                                          },
                                          [_vm._v("编辑")]
                                        ),
                                        _c(
                                          "a-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "danger"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.clearAnswer(item.id)
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              _vm.forms.type == "answer" && _vm.forms.id
                                ? _c(
                                    "a-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.visible = true
                                        }
                                      }
                                    },
                                    [_vm._v("新增回答")]
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { width: 800, title: "新增回复" },
          on: {
            ok: function($event) {
              return _vm.postAnswer(_vm.forms.id)
            }
          },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b({}, "a-form-model", _vm.layoutAsnswe, false),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "选择用户" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100%", "margin-bottom": "20px" },
                      attrs: {
                        placeholder: "选择用户",
                        "filter-option": _vm.untils.filterOption,
                        showSearch: ""
                      },
                      model: {
                        value: _vm.answerForms.userId,
                        callback: function($$v) {
                          _vm.$set(_vm.answerForms, "userId", $$v)
                        },
                        expression: "answerForms.userId"
                      }
                    },
                    _vm._l(_vm.user_list, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  "max-width": "20px",
                                  "max-height": "20px",
                                  "border-radius": "50%",
                                  "margin-right": "10px"
                                },
                                attrs: { src: item.userAvatar, alt: "" }
                              }),
                              _c("span", { attrs: { text: item.userName } }, [
                                _vm._v(_vm._s(item.userName))
                              ])
                            ]
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "回复内容" } },
                [
                  _c("quillEditor", {
                    staticStyle: { "min-height": "200px" },
                    attrs: { options: _vm.options },
                    model: {
                      value: _vm.answerForms.answerContent,
                      callback: function($$v) {
                        _vm.$set(_vm.answerForms, "answerContent", $$v)
                      },
                      expression: "answerForms.answerContent"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }